import React from "react"
import { graphql, Link as GLink } from "gatsby"
import { DiscussionEmbed } from "disqus-react"
import { Card, Box, Styled, Flex, Link } from "theme-ui"
import PageLayout from "./page-layout"
import Wrapper from "../components/wrapper"
import BlogRelatedData from "../components/blog-related-data"

function AltLink({ to, dir, children }) {
  return (
    <Link
      sx={{
        color: "text",
        direction: dir,
        py: 5,
        px: 2,
        fontSize: 13,
        position: "relative",
        "::before": {
          content: "''",
          display: "block",
          height: "2px",
          width: "40px",
          background: "rgba( 0, 0, 0, 0.1 )",
          transition: "0.25s ease-in-out",
          marginBottom: 15,
        },
        ":hover": {
          color: "primary",
          textDecoration: "none",
          "::before": {
            width: 100,
            backgroundColor: "primary",
          },
        },
      }}
      as={GLink}
      to={to}
    >
      {children}
    </Link>
  )
}

export default function BlogLayout({
  data: {
    markdownRemark: {
      frontmatter: { title, img, lang, category, ...frontmatter },
      html,
      fields: { slug },
    },
    prev,
    next,
    ...data
  },
  ...props
}) {
  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: slug, title },
  }
  const breadcrumbs =
    lang === "ar"
      ? [
          { title: "المدونة", path: "/blog/" },
          { title: title, path: slug },
        ]
      : [
          { title: "Blog", path: "/en/blog/" },
          {
            title: title,
            path: slug,
          },
        ]

  return (
    <PageLayout
      breadcrumbsPaths={breadcrumbs}
      backgroundColor="#000"
      opacity={0.3}
      mainImage={img?.childImageSharp?.fluid || {}}
      title={title}
    >
      <Wrapper style={{ py: 80 }}>
        <Box sx={{ backgroundColor: "light" }}>
          <Card
            sx={{
              bg: "background",
              p: 35,
              pb: 45,
              pt: 0,
              boxShadow: "0 2px 5px 0 rgba(0,0,0,0.07)",
            }}
          >
            <BlogRelatedData {...frontmatter} />
            <Styled.h2
              style={{
                paddingTop: 20,
                paddingBottom: 20,
                borderBottom: "1px dashed #e5e5e5",
              }}
            >
              {title}
            </Styled.h2>
            <Box
              sx={{
                p: {
                  fontSize: "1.1em",
                  lineHeight: "1.7em",
                  fontWeight: "400",
                  color: "#777",
                },
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </Box>
          </Card>
          <Box>
            <Flex sx={{ justifyContent: "space-between" }}>
              {prev ? (
                <AltLink to={prev?.fields.slug}>
                  {prev?.frontmatter.title}
                </AltLink>
              ) : (
                <div />
              )}
              {next ? (
                <AltLink
                  dir={lang === "ar" ? "ltr" : "rtl"}
                  to={next?.fields.slug}
                >
                  {next?.frontmatter.title}
                </AltLink>
              ) : (
                <div />
              )}
            </Flex>
          </Box>
        </Box>

        <DiscussionEmbed {...disqusConfig} />
      </Wrapper>
    </PageLayout>
  )
}

export const postQuery = graphql`
  query($slug: String!, $next: String, $prev: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "YYYY. MM. DD")
        author
        category
        lang
        img {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    next: markdownRemark(fields: { slug: { eq: $next } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "YYYY. MM. DD")
        author
        category
        lang
        img {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    prev: markdownRemark(fields: { slug: { eq: $prev } }) {
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "YYYY. MM. DD")
        author
        category
        lang
        img {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`
